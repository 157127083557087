// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {cm3V_PsYJ: {hover: true}};

const cycleOrder = ["cm3V_PsYJ"];

const serializationHash = "framer-uGB21"

const variantClassNames = {cm3V_PsYJ: "framer-v-jnbo40"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link2, title, width, ...props}) => { return {...props, B5gSm8bub: link2 ?? props.B5gSm8bub, L8G08PkFU: title ?? props.L8G08PkFU ?? "Contact me"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, L8G08PkFU, B5gSm8bub, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cm3V_PsYJ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={B5gSm8bub} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-jnbo40", className, classNames)} framer-96vbc2`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cm3V_PsYJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"cm3V_PsYJ-hover": {opacity: 0.8}}} {...addPropertyOverrides({"cm3V_PsYJ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTUwMA==", "--framer-font-family": "\"Open Sans\", sans-serif", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-r6o4lv, rgb(30, 29, 48))"}}>Contact me</motion.p></React.Fragment>} className={"framer-1roe8qn"} data-framer-name={"Contact me"} fonts={["GF;Open Sans-500"]} layoutDependency={layoutDependency} layoutId={"KxHAeknrP"} style={{"--extracted-r6o4lv": "rgb(30, 29, 48)", opacity: 1}} text={L8G08PkFU} variants={{"cm3V_PsYJ-hover": {opacity: 0.8}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uGB21.framer-96vbc2, .framer-uGB21 .framer-96vbc2 { display: block; }", ".framer-uGB21.framer-jnbo40 { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-uGB21 .framer-1roe8qn { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uGB21.framer-jnbo40 { gap: 0px; } .framer-uGB21.framer-jnbo40 > * { margin: 0px; margin-bottom: calc(2px / 2); margin-top: calc(2px / 2); } .framer-uGB21.framer-jnbo40 > :first-child { margin-top: 0px; } .framer-uGB21.framer-jnbo40 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 87
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"p2Q39V7dz":{"layout":["auto","auto"]}}}
 * @framerVariables {"L8G08PkFU":"title","B5gSm8bub":"link2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbmATixIO_: React.ComponentType<Props> = withCSS(Component, css, "framer-uGB21") as typeof Component;
export default FramerbmATixIO_;

FramerbmATixIO_.displayName = "Contact";

FramerbmATixIO_.defaultProps = {height: 19, width: 87};

addPropertyControls(FramerbmATixIO_, {L8G08PkFU: {defaultValue: "Contact me", displayTextArea: false, title: "Title", type: ControlType.String}, B5gSm8bub: {title: "Link 2", type: ControlType.Link}} as any)

addFonts(FramerbmATixIO_, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4iY1M2xLER.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})